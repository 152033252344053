<template>
	<div class="ele-body">
		<el-card shadow="never">
			<!-- 搜索表单 -->
			<div>
				<el-form :model="table.where" class="ele-form-search d-flexspabet" @keyup.enter.native="$refs.table.reload()"
					@submit.native.prevent>

					<div class="d-flex" style="display: flex;">
						<el-form-item label="省:" label-width="35px" class="w-170">
							<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder="请选择省"
								clearable>
								<el-option v-for="option in provArr" :label="option.name" :value="option.pid"
									:key="option.pid"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="市:" label-width="35px" class="w-150">
							<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid), $forceUpdate()"
								placeholder="请选择市" clearable>
								<el-option v-for="option in cityArr" :label="option.name" :value="option.cid"
									:key="option.cid"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="县/区:" label-width="62px" class="w-200 mr-20">
							<el-select v-model="table.where.aid" @change="$forceUpdate()" placeholder="请选择县/区" clearable>
								<el-option v-for="option in districtArr " :label="option.name" :value="option.aid"
									:key="option.aid"></el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="老人姓名:" label-width="130px" class="w-300">
							<el-input v-model="table.where.name" placeholder="请输入姓名" clearable />
						</el-form-item>
						<el-form-item label="老人手机号:" label-width="130px" class="w-300">
							<el-input v-model="table.where.phone" placeholder="请输入手机号" clearable />
						</el-form-item>
						<el-form-item label="老人座机号:" label-width="130px" class="w-300">
							<el-input v-model="table.where.plane_phone" placeholder="请输入座机号" clearable />
						</el-form-item>
						<el-form-item label="子女手机号:" label-width="130px" class="w-300">
							<el-input v-model="table.where.child_phone" placeholder="请输入子女手机号" clearable />
						</el-form-item>
						<el-form-item label="创建时间:" label-width="100px">
							<el-date-picker v-model="table.where.create_time" type="date" value-format="yyyy-MM-dd"
								placeholder="选择开始时间" clearable>
							</el-date-picker>
							-
							<el-date-picker v-model="table.where.update_time" type="date" value-format="yyyy-MM-dd"
								placeholder="选择结束时间" clearable>
							</el-date-picker>
						</el-form-item>

						<el-form-item style="margin-left: 10px;">
							<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
								class="ele-btn-icon">搜索</el-button>
							<!-- <el-button  class="ele-btn-icon" @click="(table.where={})&&$refs.table.reload()">重置</el-button> -->
						</el-form-item>

						<el-form-item style="margin-left: 10px;">
							<!-- <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
                            class="ele-btn-icon">搜索</el-button> -->

							<el-button class="ele-btn-icon" @click="(table.where = {}) && $refs.table.reload()">重置</el-button>
							<download ref="childDownload" style="margin-left: 0" class="ele-btn-icon ml-20" label="导出"
								url="HelpAge/exportExcel" :outtime='1000' :params="exportOrderParams()" />



							<!-- <el-button  class="ele-btn-icon" @click="(table.where={})&&$refs.table.reload()">重置</el-button> -->

                        </el-form-item>
                        <el-upload  action="" :http-request="upload" :before-upload="beforeUploadFile"
												:show-file-list="false"
												class="upload-demo">
                            <el-button type="primary"><i class="el-icon-upload2"></i>导入 </el-button>
                        </el-upload>
                    </div>
                </el-form>
            </div>
            <!-- 数据表格 -->
            <ele-data-table ref="table" :fit="true" :config="table" :choose.sync="choose" :stripe=true>
                <template slot-scope="{index}">
                    <el-table-column type="selection" width="45" align="center" fixed="left" />
                    <el-table-column type="index" :index="index" label="编号" align="center" fixed="left" width="70"
                        show-overflow-tooltip />
                    <el-table-column prop="area" label="所属地区" align="center" show-overflow-tooltip min-width="50" />
                    <el-table-column prop="name" label="老人姓名" align="center" show-overflow-tooltip min-width="50" />
                    <el-table-column prop="sex" label="性别" align="center" show-overflow-tooltip min-width="50">
                        <template slot-scope="{row}">
                            {{ row.sex == 1 ? '男' : '女' }}
                        </template>
                    </el-table-column>



					<el-table-column prop="age" label="年龄(岁)" align="center" show-overflow-tooltip min-width="50" />
					<el-table-column prop="phone" label="手机号" align="center" show-overflow-tooltip min-width="50" />
					<el-table-column prop="plane_phone" label="座机号" align="center" show-overflow-tooltip min-width="50" />
					<el-table-column prop="usual_address" label="常用地址" align="center" show-overflow-tooltip min-width="50" />
					<el-table-column prop="user_phone" label="子女手机号" align="center" show-overflow-tooltip min-width="50" />
					<el-table-column prop="order_count" label="累计叫单次数（次）" align="center" show-overflow-tooltip min-width="50" />
					<el-table-column prop="create_time" label="创建时间" align="center" show-overflow-tooltip min-width="50" />
					<!-- <el-table-column prop="update_time" label="更新时间" align="center" show-overflow-tooltip min-width="50" /> -->

					<el-table-column label="操作" min-width="100px" align="center" :resizable="false" fixed="right">
						<template slot-scope="{row}">
							<!-- <el-link slot="reference" @click="set_status(row)"
                                :icon="row.open_status==1?'el-icon-error':'el-icon-success'"
                                :type="row.open_status==1?'danger':'primary'" :underline="false"
                                v-if="permission.includes('sys:rreach:start')">
                                {{row.open_status==1?'关闭':row.open_status==0?'开启':''}}
                            </el-link> -->
							<el-link @click="edit(row)" slot="reference" icon="el-icon-edit" type="primary" :underline="false"
								v-if="permission.includes('sys:zhulao:edit')">查看/编辑</el-link>
							<el-link @click="remove(row)" slot="reference" icon="el-icon-delete" type="danger" :underline="false"
								v-if="permission.includes('sys:zhulao:delete')">删除</el-link>
                        <el-link @click="study(row)" v-if="permission.includes('sys:zhulao:study')" 
							 slot="reference" icon="el-icon-collection" type="success" :underline="false">智能学习</el-link>
						</template>
					</el-table-column>
				</template>
			</ele-data-table>
		</el-card>
		<!-- 编辑弹窗 -->
		<!--添加/编辑弹窗 -->
		<el-dialog v-dialogDrag :title="editForm.id ? '编辑助老信息' : '添加助老信息'" :visible.sync="showEdit" @closed="editForm = {}"
			:destroy-on-close="true" :lock-scroll="false">
				<el-form :model="editForm" ref="editForm" :rules="editRules" label-width="200px">
					<el-row :gutter="15" style="padding: 10px 0;">
						<el-col :sm="12" style="padding-right: 60px;border-right: 1px solid #C6C6C6;">
							<el-form-item label="所属地区：" prop="area">
								<el-input v-model="editForm.area" controls-position="right" placeholder="" clearable class="input163"
									style=" width:100%" :disabled="true" />
							</el-form-item>
		
							<el-form-item label="老人姓名：" prop="name">
								<el-input v-model="editForm.name" controls-position="right" placeholder="" clearable class="input163"
									style=" width:100%" />
							</el-form-item>
		
							<el-form-item label="性别：" prop="sex">
								<el-radio-group v-model="editForm.sex">
									<el-radio :label="1">男</el-radio>
									<el-radio :label="2">女</el-radio>
								</el-radio-group>
							</el-form-item>
		
							<el-form-item label="老人年龄：" prop="age">
								<el-input v-model="editForm.age" controls-position="right" placeholder="" clearable class="input163"
									style=" width:100%" />
							</el-form-item>
							<el-form-item label="手机号：" prop="phone">
								<el-input v-model="editForm.phone" controls-position="right" placeholder="" clearable class="input163"
									style=" width:100%" />
							</el-form-item>
							<el-form-item label="座机号：" prop="plane_phone">
								<el-input v-model="editForm.plane_phone" controls-position="right" placeholder="" clearable
									class="input163" style=" width:100%" />
							</el-form-item>
							<el-form-item label="常住地址：" prop="usual_address">
								<el-input v-model="editForm.usual_address" type="textarea" controls-position="right" placeholder="" clearable
									class="input163" style=" width:100%" />
							</el-form-item>
							<el-form-item label="子女手机号：" prop="user_phone">
								<el-input v-model="editForm.user_phone" controls-position="right" placeholder="" clearable
									class="input163" style=" width:100%" :disabled="true" />
							</el-form-item>
							<el-form-item label="累计叫单次数：" prop="order_count">
								<el-input v-model="editForm.order_count" controls-position="right" placeholder="" clearable
									class="input163" style=" width:100%" :disabled="true" />
							</el-form-item>
							<el-form-item label="创建时间：" prop="create_time">
								<el-input v-model="editForm.create_time" controls-position="right" placeholder="" clearable
									class="input163" style=" width:100%" :disabled="true" />
							</el-form-item>
						</el-col>
		
						<el-col :sm="12">
								<el-form-item label="常用地址：" label-width='130px'>
									<div v-if="editForm.common_address!=''">
										<el-input v-for="(val, index) in editForm.common_address" :key="index"
										type="textarea" style="width: 80%;"
											:style="{marginTop: index === 0 ? '0px' : '10px'}" v-model="val.address" controls-position="right"
											placeholder="" clearable class="input163" :disabled="false" />
										
									</div>
									<!-- 新增常用地址 -->
									<div  v-for="(item,index) in editForm.add_common_address" :key="index" v-if="editForm.add_common_address" style="display: flex; align-items: center;">
										<!-- <el-input type="textarea" style="width: 80%; margin-top: 10px;"
										controls-position="right"									
										 v-model="item.address"></el-input> -->
										 <el-autocomplete style="width: 80%; margin-top: 10px;"
										 popper-class="my-autocomplete" v-model="item.value" :debounce="300"
										 	:fetch-suggestions="querySearchAsync" :trigger-on-focus="false" placeholder="输入关键词"
										 	class="order-input" @select="schange">
										 </el-autocomplete>
										 <i @click="addAdress(item)" class="el-icon-circle-plus" 
										 v-if="addLength !=1 && index == editForm.add_common_address.length -1"
										 style="font-size: 30px; color:#33CC99; margin-top: 10px; margin-left: 10px; cursor: pointer;" ></i>
									</div>
									<!-- <b v-else>
										暂无地址
									</b> -->
								</el-form-item>
								<el-form-item label="常去地址：" label-width='130px'>
									<div v-if="editForm.frequently_visited_address!=''">
										<el-input v-for="(val, index) in editForm.frequently_visited_address" :key="index"
										type="textarea"
											:style="{marginTop: index === 0 ? '0px' : '10px'}" v-model="val.address" controls-position="right"
											placeholder="" clearable style="width: 80%;" class="input163" :disabled="false" />
									</div>
									<!-- 新增常去地址 -->
									<div  v-for="(item,index) in editForm.add_frequently_visited_address" :key="index" 
									v-if="editForm.add_frequently_visited_address" style="display: flex; align-items: center;">
										<!-- <el-input type="textarea" style="width: 80%; margin-top: 10px;"
										controls-position="right"									
										 v-model="item.address"></el-input> -->
										 <el-autocomplete style="width: 80%; margin-top: 10px;"
										 popper-class="my-autocomplete" v-model="item.value" :debounce="300"
										 	:fetch-suggestions="querySearchAsync2" :trigger-on-focus="false" placeholder="输入关键词"
										 	class="order-input" @select="schange2">
										 </el-autocomplete>
										 <i @click="addAdress2(item)" class="el-icon-circle-plus" 
										 v-if="addLength2 != 1 && index == editForm.add_frequently_visited_address.length -1"
										 style="font-size: 30px; color:#33CC99; margin-top: 10px; margin-left: 10px; cursor: pointer;" ></i>
									</div>
								<!-- 	<b v-else>
										暂无地址
									</b> -->
								</el-form-item>
						</el-col>
		
		
					</el-row>
				</el-form>
			<div slot="footer">
				<el-button @click="showEdit = false">取消</el-button>
				<el-button type="primary" @click="save">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import download from '@/components/ListDownload/index'
	// import { Loading } from 'element-ui';
	export default {
		components: {
			download
		},
		name: "SysLimitNumbers",
		watch: {
			$route() {
				//切换标签时
				// this.handleRefreshTable();
			}
		},
		data() {
			return {
				table: {
					url: '/HelpAge/index',
					where: {}
				}, // 表格配置
				choose: [], // 表格选中数据
				showEdit: false, // 是否显示表单弹窗
				editForm: {}, // 表单数据
				editRules: { // 表单验证规则
					pid: [{
						required: true,
						message: '请选择省份',
						trigger: 'change'
					}]
				},
				provArr: [],
				cityArr: [],
				districtArr: [],
				activeName: 'first',
				activeEdit: 'run-driver',
				pid: '',
				cid: '',
				aid: '',
				changePro: false,
				changeCity: false,
				changeArea: false,
				addLength:'',//常用可以添加的数量
				addLength2:'',//常去可以添加的数量
				// add_common_address:[],
				// add_frequently_visited_address:[]
			}
		},
		created() {
			let phoneQuery = this.$route.query
			if(phoneQuery.phone) {
				let phone = phoneQuery.phone.split('/')[0]
				this.$http.get('/HelpAge/index?help_phone=' + phone)
				 .then(res => {
					 console.log('res',res)
					 if(res.data.code == 0) {
						 this.edit(res.data.data[0])
					 }else {
						 this.$message(res.data.data.msg)
					 }
				 }).catch(err => {
						this.$message(res.data.data.msg)
				 })
			}
			let _this = this;
			this.$http.get('/common/province_list').then(res => {
				let data = JSON.parse(res.data)
				this.provArr = data
			})
			window.onresize = function() {
				// _this.handleRefreshTable();
			};

		},
		computed: {
			...mapGetters(["permission"]),
		},
		updated() {
			//数据改变时
			// this.handleRefreshTable();
		},
		mounted() {},
		methods: {
			//查询添加常去地址
			schange2(item) {
				let index = this.editForm.add_frequently_visited_address.length - 1
				this.editForm.add_frequently_visited_address[index] = item
			},
			querySearchAsync2(query, cb) {
				if (query) {
					fetch(
							`https://restapi.amap.com/v3/place/text?keywords=${query}&offset=10&page=1&key=776a143516222c0a4ede18ae5dce8587`, {			
								method: 'get'
							}).then(response => response.json())
						.then(res => {
							console.log('res', res)
							if (res.infocode == 10000) {
								let sOptions = res.pois
								sOptions.forEach(item => {
									item.value = item.name
									item.label = item.value
								})
								var restaurants = sOptions;
								cb(restaurants);
								// cb(restaurants);
							} else {
								this.$message({
									message: res.info
								});
							}
						})
				}
			},			
			//新增常去地址
			addAdress2(item) {
				if(item.id) {
					this.editForm.add_frequently_visited_address.push({})
					this.addLength2--
				}else {
					this.$message('请输入并选择地址')
				}
			},
			//查询添加常用地址
			schange(item) {
				let index = this.editForm.add_common_address.length - 1
				this.editForm.add_common_address[index] = item
			},
			querySearchAsync(query, cb) {
				if (query) {
					fetch(
							`https://restapi.amap.com/v3/place/text?keywords=${query}&offset=10&page=1&key=776a143516222c0a4ede18ae5dce8587`, {			
								method: 'get'
							}).then(response => response.json())
						.then(res => {
							console.log('res', res)
							if (res.infocode == 10000) {
								let sOptions = res.pois
								sOptions.forEach(item => {
									item.value = item.name
									item.label = item.value
								})
								var restaurants = sOptions;
								cb(restaurants);
								// cb(restaurants);
							} else {
								this.$message({
									message: res.info
								});
							}
						})
				}
			},
			//新增常用地址
			addAdress(item) {
				if(item.id) {
					this.editForm.add_common_address.push({})
					this.addLength--
				}else {
					this.$message('请输入并选择地址')
				}
			},
			beforeUploadFile(file) {
				//console.log('before upload')
				//console.log(file)
				let extension = file.name.substring(file.name.lastIndexOf('.') + 1)
				let size = file.size / 1024 / 1024
				if (extension !== 'xlsx') {
					this.$message.warning('只能上传后缀是.xlsx的文件')
				}
				if (size > 10) {
					this.$message.warning('文件大小不得超过10M')
				}
				
			},
			// 上传
			upload(param) {
				const loading = this.$loading({
					lock: true,
					text:'文件上传中...'
				})
				const formData = new FormData()
				formData.append('file', param.file)
				const url = 'HelpAge/import/'
				this.axios
					.post(url, formData)
					.then((data) => {
						loading.close()
						this.$message({
							type: 'success',
							message: '导入成功'
						});
						this.$refs.table.reload();
						// //console.log('上传图片成功')
					})
					.catch((response) => {
						loading.close()
						this.$message({
							type: 'error',
							message: '导入失败'
						});
					})
			},
			// 导出数据参数
			exportOrderParams() {
				const query = this.table.where
				const selectionIds = this.choose.map(item => {
					return item.id
				})
				return {
					ids: selectionIds,
					...query
				}
			},
			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if (e) {
					/** 获取被选市的cid**/
					let cid = ''
					this.cityArr.forEach(function(item) {
						if (item.cid == e) {
							cid = item.cid
						}
					})
					/** 根据被选市的cid获取市下面的县**/
					this.$http.post('/common/area_list', {
						cid: cid
					}).then(res => {
						let data = JSON.parse(res.data)
						this.districtArr = data
						/** 选择省份清空县**/
						this.table.where.aid = ''
					})
				} else {
					// this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},

			/**
			 *修改省
			 **/
			handleChangeProv1(e) {
				/** 获取被选省份的pid**/
				if (e) {
					let pid = ''
					let that = this
					this.changePro = true
					this.provArr.forEach(function(item) {
						if (item.pid == e) {
							pid = item.pid
						}
					})
					/** 根据被选省份的pid获取省市下面的市**/
					this.$http.post('/common/city_list', {
						pid: pid
					}).then(res => {
						let data = JSON.parse(res.data)
						that.cityArr = data
						/** 选择省份清空市县**/
						this.editForm.cid = ''
						this.editForm.aid = ''
						this.editForm.cname = ''
						this.editForm.aname = ''
					})
				} else {
					this.editForm.pid = ''
					this.editForm.cid = ''
					this.editForm.aid = ''
					this.editForm.pname = ''
					this.editForm.cname = ''
					this.editForm.aname = ''
				}

			},
			/**
			 *修改市
			 **/
			handleChangeCity1(e) {
				if (e) {
					/** 获取被选市的cid**/
					let cid = ''
					this.changeCity = true
					this.cityArr.forEach(function(item) {
						if (item.cid == e) {
							cid = item.cid
						}
					})
					/** 根据被选市的cid获取市下面的县**/
					this.$http.post('/common/area_list', {
						cid: cid
					}).then(res => {
						let data = JSON.parse(res.data)
						this.districtArr = data
						/** 选择省份清空县**/
						this.editForm.aid = ''
						this.editForm.aname = ''
					})
				} else {
					// this.editForm.cname = ''
					this.editForm.aid = ''
					this.editForm.aname = ''
				}
			},
			handleDis() {
				this.changeArea = true
			},
			/**
			 * 显示编辑
			 */
			edit(row) {
				//console.log('row', row);
				this.pid = row.pid
				this.cid = row.cid
				this.aid = row.aid
				
				this.editForm = JSON.parse(JSON.stringify(row));
				this.editForm.pid = row.pid
				this.editForm.cname = row.cid;
				this.editForm.aname = row.aid;
				if(this.editForm.common_address.length < 5) { //判断常用地址是否可以在添加
					//获取可以添加的数量
					this.addLength = 5 - this.editForm.common_address.length
					//设置新添加地址的数组
					this.$set(this.editForm,'add_common_address',[])
					this.editForm.add_common_address.push({})
				}
				if(this.editForm.frequently_visited_address.length < 5) { //判断常去地址是否可以在添加
					//获取可以添加的数量
					this.addLength2 = 5 - this.editForm.frequently_visited_address.length
					//设置新添加地址的数组
					this.$set(this.editForm,'add_frequently_visited_address',[])
					this.editForm.add_frequently_visited_address.push({})
					console.log('this.editForm',this.editForm)
					console.log('add_frequently_visited_address',this.editForm.add_frequently_visited_address.length)
				}
				this.showEdit = true;

				if (this.editForm.pid != '') {
					this.$http.post('/common/city_list', {
						pid: this.editForm.pids

					}).then(res => {
						let cdata = JSON.parse(res.data)
						this.cityArr = cdata
					})
				}
				if (this.editForm.cid != '') {
					this.$http.post('/common/area_list', {
						cid: this.editForm.cid
					}).then(res => {
						let adata = JSON.parse(res.data)
						this.districtArr = adata
					})
				}

				// this.$http.get('/limitnumbers/info?id=' + row.id).then(res => {
				//   if (res.data.code === 0) {
				//     if(res.data.data.type==1){
				//       res.data.data.type='出租车'
				//     }else  if(res.data.data.type==2){
				//       res.data.data.type='拼车'
				//     }else if(res.data.data.type==3){
				//       res.data.data.type='帮我买'
				//     }else if(res.data.data.type==4){
				//       res.data.data.type='取送件'
				//     }else if(res.data.data.type==5){
				//       res.data.data.type='超市'
				//     }else {
				//       res.data.data.type='叫餐'
				//     }
				//     res.data.data.pid=res.data.data.pname
				//     res.data.data.cid=res.data.data.cname
				//     res.data.data.aid=res.data.data.aname
				//     this.editForm = res.data.data;
				//     this.showEdit = true;
				//     //console.log( this.editForm)
				//   } else {
				//     this.$message.error(res.data.msg);
				//   }
				// }).catch(e => {
				//   this.$message.error(e.message);
				// });
			},
			/**
			 * 保存编辑
			 */
			save() {
				this.$refs['editForm'].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/help_age/editHelpAged', this.editForm).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.showEdit = false;
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
			},
			/**
			 * 刪除(批量刪除)
			 */
			remove(row) {
				//console.log(row)
				if (!row) { // 批量删除
					if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
					let ids = this.choose.map(d => d.id);
					this.$confirm('确定要删除选中的设置吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/HelpAge/del', {
							id: ids
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					}).catch(() => 0);
				} else { // 单个删除
					this.$confirm('确定要删除选中的设置吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/HelpAge/del', {
							id: row.id
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				}
			},
			study(row) {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/HelpAge/studyAddress',{ id:row.id}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.showEdit = false;
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
				
				
			},
		}
	}
</script>

<style scoped>
	.ele-block>>>.el-upload,
	.ele-block>>>.el-upload-dragger {
		width: 100%;
	}

	.ele-block>>>.el-upload,
	.ele-block>>>.el-upload-dragger {
		width: 100%;
	}

	.driverForm /deep/.el-form-item__content {
		margin-left: 0 !important
	}

	/deep/.el-tabs__nav {
		margin-left: 20px
	}

	.prompt {
		color: #FF0000;
		font-size: 14px;
	}
</style>